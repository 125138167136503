import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KiloCheckout, KiloOrder, KiloProduct } from '@core/checkout-web';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import { useSelector } from 'react-redux';
import { handleError as trackErrorSentry } from 'utils/error';
import { useQuizData } from 'utils/hooks';

import { getLocalisedProduct, normalizeStates } from 'utils/localization';
import Tracking from 'utils/tracking';

import OldPayments from './OldPayments';
import Payments from './Payments';
import { store } from '../../index';

export const PAYMENT_METHOD = {
  stripe: 'kilo-payment-primer_payments',
  paypal: 'kilo-payment-paypal_direct',
};

export const KiloPayments = ({
  code,
  children,
  handleError,
  handleSuccess,
  initialProduct,
}) => {
  const checkoutRef = useRef(new KiloCheckout());
  const IS_STYLE_LOADED = useRef(false);

  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const urlConfigs = useSelector(state => state.payment?.gateway);
  const clientEmail = useSelector(state => state.user.user?.email);
  const data = useQuizData('payments');
  const user = useSelector(state => state.user);

  const paymentIds = useMemo(() => {
    const ids = Object.keys(urlConfigs?.prepare_urls ?? []).map(
      url => `kilo-payment-${url}`,
    );
    if (data?.arePaymentsReversed) {
      return [...ids].reverse();
    }
    return ids;
  }, [urlConfigs, data?.arePaymentsReversed]);

  const loadStyle = href =>
    new Promise(resolve => {
      if (IS_STYLE_LOADED?.current || !href) {
        resolve();
        return;
      }

      const element = document.createElement('link');
      const attributes = {
        id: 'kilo-style-' + btoa(href),
        rel: 'stylesheet',
        href,
      };

      each(attributes, (value, name) => (element[name] = value));

      element.onload = () => {
        IS_STYLE_LOADED.current = true;
        resolve();
      };

      document['head'].appendChild(element);
    });

  const load = async () => {
    if (urlConfigs?.style) {
      try {
        await loadStyle(urlConfigs.style);
      } catch (error) {
        console.log(error);
      }
    }

    createUi();
  };

  const onSuccess = data => {
    try {
      const localisedProduct = getLocalisedProduct(initialProduct);

      Tracking.initiatePurchase(
        clientEmail,
        user.code,
        data?.order_id,
        store.getState().user.selected_plans,
        user.geolocation?.iso_country,
        normalizeStates(
          user.geolocation?.iso_country || '',
          user.geolocation?.iso_state || '',
        ),
        user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
        user.user_ip,
      );
      // Analytics.trackPurchase(
      //   data?.order_id,
      //   initialProduct?.currencyId,
      //   initialProduct?.sku,
      //   initialProduct?.name,
      //   initialProduct?.finalPrice,
      // );
      // // purchase event for GA4
      // Analytics.trackSuccessPurchase({
      //   purchase_type: 'initial',
      //   ecommerce: {
      //     email: clientEmail,
      //     transaction_id: data?.order_id,
      //     value: Number(initialProduct?.finalPrice),
      //     currency: initialProduct?.currencyId,
      //     items: [
      //       {
      //         item_name: initialProduct?.name,
      //         item_id: initialProduct?.sku,
      //         price: Number(initialProduct?.finalPrice),
      //         item_category: 'initial',
      //         quantity: 1,
      //       },
      //     ],
      //   },
      // });
      if (typeof handleSuccess === 'function') {
        handleSuccess(data);
      }
    } catch (e) {
      trackErrorSentry(e);
    }

    return false;
  };

  const onSubmit = data => {
    Tracking.trackPaymentSubmit(data.method, data.product);
    if (data?.payment_provider === 'paypal_direct') {
      return;
    }
    setLoading(true);
  };

  const onError = e => {
    console.log(e);
    if (e.length === 0) {
      return false;
    }

    setLoading(false);
    if (typeof e === 'string') {
      setErrorMessage(e);
    }
    if (typeof handleError === 'function') {
      handleError(e);
    }
    return false;
  };

  const createUi = async () => {
    try {
      setLoading(true);
      const localisedProducs = initialProduct?.map(product =>
        getLocalisedProduct(product),
      );

      const calculateFullPrice = products => {
        let fullPrice = 0;

        for (let i = 0; i < products.length; i++) {
          fullPrice = fullPrice + Number(products[i].finalPrice);
        }

        return fullPrice;
      };

      const kiloOrderObj = {
        amount: Number(calculateFullPrice(localisedProducs).toFixed(2)),
        amount_in_cents: Math.floor(calculateFullPrice(localisedProducs) * 100),
        title: 'Nordastro',

        products: localisedProducs?.map(product => ({
          key: product.key,
          quantity: 1,
          price: 20,
        })),
      };

      const order = new KiloOrder(kiloOrderObj);

      if (urlConfigs.prepare_urls) {
        await Promise.all(
          Object.keys(urlConfigs.prepare_urls).map(async url => {
            await checkoutRef.current.create(urlConfigs.prepare_urls[url], {
              order,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks: {
                onError,
                onSubmit,
                onSuccess,
              },
            });
          }),
        );
      }
    } catch (e) {
      console.log(e);
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      load();
    }
  }, [code]);

  useEffect(() => {
    const idx = data?.selectedPaymentIndex;
    if (idx && idx < paymentIds?.length) {
      return setSelectedId(paymentIds?.[idx] ?? '');
    }
    setSelectedId(paymentIds?.[0] ?? '');
  }, [paymentIds, data?.selectedPaymentIndex]);

  useEffect(() => {
    const localisedProducts = initialProduct?.map(product =>
      getLocalisedProduct(product),
    );

    const calculateFullPrice = products => {
      let fullPrice = 0;

      for (let i = 0; i < products.length; i++) {
        fullPrice = fullPrice + Number(products[i].finalPrice);
      }

      return fullPrice;
    };

    const kiloOrderObj = {
      amount: Number(calculateFullPrice(localisedProducts).toFixed(2)),
      amount_in_cents: Math.floor(calculateFullPrice(localisedProducts) * 100),
      title: 'Nordastro',

      products: localisedProducts?.map(product => ({
        key: product.key,
        quantity: 1,
        price: 20,
      })),
    };

    const order = new KiloOrder(kiloOrderObj);

    checkoutRef.current.setOrder(order, true);
  }, [initialProduct]);

  return (
    <>
      {/* {location.search.includes('tst3') ? (
          <Payments
            ids={paymentIds}
            selectedId={selectedId}
            onItemClick={setSelectedId}
          />
        ) : (
          <OldPayments
            ids={paymentIds}
            selectedId={selectedId}
            onItemClick={setSelectedId}
          />
        )} */}
      <Payments
        ids={paymentIds}
        selectedId={selectedId}
        onItemClick={setSelectedId}
        errorMessage={errorMessage}
      />

      {/* {loading ? children : null} */}
    </>
  );
};

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};
