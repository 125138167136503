import arrowHeart from 'assets/icons/emoji/arrow-heart.svg';
import baby from 'assets/icons/emoji/baby.svg';
import balance from 'assets/icons/emoji/balance.svg';
import bigCheekSmile from 'assets/icons/emoji/big-cheek-smile.svg';
import books from 'assets/icons/emoji/books.svg';
import brain from 'assets/icons/emoji/brain.svg';
import briefcase from 'assets/icons/emoji/briefcase.svg';
import brokenHeart from 'assets/icons/emoji/broken-heart.svg';
import celebrate from 'assets/icons/emoji/celebrate.svg';
import cheekSmile from 'assets/icons/emoji/cheek-smile.svg';
import clock from 'assets/icons/emoji/clock.svg';
import colors from 'assets/icons/emoji/colors.svg';
import compass from 'assets/icons/emoji/compass.svg';
import correct from 'assets/icons/emoji/correct.svg';
import couple from 'assets/icons/emoji/couple.svg';
import crossedHands from 'assets/icons/emoji/crossed-hands.svg';
import diamond from 'assets/icons/emoji/diamond.svg';
import disappointed from 'assets/icons/emoji/disappointed.svg';
import enjoy from 'assets/icons/emoji/enjoy.svg';
import ew from 'assets/icons/emoji/ew.svg';
import facePalm from 'assets/icons/emoji/face-palm.svg';
import family from 'assets/icons/emoji/family.svg';
import finding from 'assets/icons/emoji/finding.svg';
import fireHeart from 'assets/icons/emoji/fire-heart.svg';
import gift from 'assets/icons/emoji/gift.svg';
import graduation from 'assets/icons/emoji/graduation.svg';
import gymnastic from 'assets/icons/emoji/gymnastic.svg';
import headMassage from 'assets/icons/emoji/head-massage.svg';
import healingHeart from 'assets/icons/emoji/healing-heart.svg';
import handHeart from 'assets/icons/emoji/hand-heart.svg';
import hand from 'assets/icons/emoji/hand.svg';
import heart from 'assets/icons/emoji/heart.svg';
import idk from 'assets/icons/emoji/idk.svg';
import kissing from 'assets/icons/emoji/kissing.svg';
import laugh from 'assets/icons/emoji/laugh.svg';
import lily from 'assets/icons/emoji/lily.svg';
import little from 'assets/icons/emoji/little.svg';
import loveLetter from 'assets/icons/emoji/love-letter.svg';
import loved from 'assets/icons/emoji/loved.svg';
import magicBall from 'assets/icons/emoji/magic-ball.svg';
import meh from 'assets/icons/emoji/meh.svg';
import message from 'assets/icons/emoji/message.svg';
import money from 'assets/icons/emoji/money.svg';
import numbers from 'assets/icons/emoji/numbers.svg';
import palmHand from 'assets/icons/emoji/palm-hand.svg';
import palm from 'assets/icons/emoji/palm.svg';
import pinkHearts from 'assets/icons/emoji/pink-hearts.svg';
import planet from 'assets/icons/emoji/planet.svg';
import questionMark from 'assets/icons/emoji/question-mark.svg';
import ring from 'assets/icons/emoji/ring.svg';
import rocket from 'assets/icons/emoji/rocket.svg';
import sad from 'assets/icons/emoji/sad.svg';
import serious from 'assets/icons/emoji/serious.svg';
import smile from 'assets/icons/emoji/smile.svg';
import smirk from 'assets/icons/emoji/smirk.svg';
import spade from 'assets/icons/emoji/spade.svg';
import spinningHearts from 'assets/icons/emoji/spinning-hearts.svg';
import sponge from 'assets/icons/emoji/sponge.svg';
import starEyes from 'assets/icons/emoji/star-eyes.svg';
import target from 'assets/icons/emoji/target.svg';
import thinking from 'assets/icons/emoji/thinking.svg';
import tornado from 'assets/icons/emoji/tornado.svg';
import wrong from 'assets/icons/emoji/wrong.svg';
import yoga from 'assets/icons/emoji/yoga.svg';
import backgroundZodiac from 'assets/icons/quiz/background-zodiac.svg';
import aries from 'assets/icons/zodiac/aries.svg';
import taurus from 'assets/icons/zodiac/taurus.svg';
import gemini from 'assets/icons/zodiac/gemini.svg';
import cancer from 'assets/icons/zodiac/cancer.svg';
import leo from 'assets/icons/zodiac/leo.svg';
import virgo from 'assets/icons/zodiac/virgo.svg';
import libra from 'assets/icons/zodiac/libra.svg';
import scorpio from 'assets/icons/zodiac/scorpio.svg';
import sagittarius from 'assets/icons/zodiac/sagittarius.svg';
import capricorn from 'assets/icons/zodiac/capricorn.svg';
import aquarius from 'assets/icons/zodiac/aquarius.svg';
import pisces from 'assets/icons/zodiac/pisces.svg';
import reviewStars from 'assets/icons/quiz/review-stars.svg';
// Black Icons
import blackaries from 'assets/icons/booksIcons/black_aries.svg';
import blacktaurus from 'assets/icons/booksIcons/black_taurus.svg';
import blackgemini from 'assets/icons/booksIcons/black_gemini.svg';
import blackcancer from 'assets/icons/booksIcons/black_cancer.svg';
import blackleo from 'assets/icons/booksIcons/black_leo.svg';
import blackvirgo from 'assets/icons/booksIcons/black_virgo.svg';
import blacklibra from 'assets/icons/booksIcons/black_libra.svg';
import blackscorpio from 'assets/icons/booksIcons/black_scorpio.svg';
import blacksagittarius from 'assets/icons/booksIcons/black_sagittarius.svg';
import blackcapricorn from 'assets/icons/booksIcons/black_capricorn.svg';
import blackaquarius from 'assets/icons/booksIcons/black_aquarius.svg';
import blackpisces from 'assets/icons/booksIcons/black_pisces.svg';

// Purple Icons
import purplearies from 'assets/icons/booksIcons/purple_aries.svg';
import purpletaurus from 'assets/icons/booksIcons/purple_taurus.svg';
import purplegemini from 'assets/icons/booksIcons/purple_gemini.svg';
import purplecancer from 'assets/icons/booksIcons/purple_cancer.svg';
import purpleleo from 'assets/icons/booksIcons/purple_leo.svg';
import purplevirgo from 'assets/icons/booksIcons/purple_virgo.svg';
import purplelibra from 'assets/icons/booksIcons/purple_libra.svg';
import purplescorpio from 'assets/icons/booksIcons/purple_scorpio.svg';
import purplesagittarius from 'assets/icons/booksIcons/purple_sagittarius.svg';
import purplecapricorn from 'assets/icons/booksIcons/purple_capricorn.svg';
import purpleaquarius from 'assets/icons/booksIcons/purple_aquarius.svg';
import purplepisces from 'assets/icons/booksIcons/purple_pisces.svg';

// Red Icons
import redaries from 'assets/icons/booksIcons/red_aries.svg';
import redtaurus from 'assets/icons/booksIcons/red_taurus.svg';
import redgemini from 'assets/icons/booksIcons/red_gemini.svg';
import redcancer from 'assets/icons/booksIcons/red_cancer.svg';
import redleo from 'assets/icons/booksIcons/red_leo.svg';
import redvirgo from 'assets/icons/booksIcons/red_virgo.svg';
import redlibra from 'assets/icons/booksIcons/red_libra.svg';
import redscorpio from 'assets/icons/booksIcons/red_scorpio.svg';
import redsagittarius from 'assets/icons/booksIcons/red_sagittarius.svg';
import redcapricorn from 'assets/icons/booksIcons/red_capricorn.svg';
import redaquarius from 'assets/icons/booksIcons/red_aquarius.svg';
import redpisces from 'assets/icons/booksIcons/red_pisces.svg';

// Green Icons
import greenaries from 'assets/icons/booksIcons/green_aries.svg';
import greentaurus from 'assets/icons/booksIcons/green_taurus.svg';
import greengemini from 'assets/icons/booksIcons/green_gemini.svg';
import greencancer from 'assets/icons/booksIcons/green_cancer.svg';
import greenleo from 'assets/icons/booksIcons/green_leo.svg';
import greenvirgo from 'assets/icons/booksIcons/green_virgo.svg';
import greenlibra from 'assets/icons/booksIcons/green_libra.svg';
import greenscorpio from 'assets/icons/booksIcons/green_scorpio.svg';
import greensagittarius from 'assets/icons/booksIcons/green_sagittarius.svg';
import greencapricorn from 'assets/icons/booksIcons/green_capricorn.svg';
import greenaquarius from 'assets/icons/booksIcons/green_aquarius.svg';
import greenpisces from 'assets/icons/booksIcons/green_pisces.svg';

// Blue Icons
import bluearies from 'assets/icons/booksIcons/blue_aries.svg';
import bluetaurus from 'assets/icons/booksIcons/blue_taurus.svg';
import bluegemini from 'assets/icons/booksIcons/blue_gemini.svg';
import bluecancer from 'assets/icons/booksIcons/blue_cancer.svg';
import blueleo from 'assets/icons/booksIcons/blue_leo.svg';
import bluevirgo from 'assets/icons/booksIcons/blue_virgo.svg';
import bluelibra from 'assets/icons/booksIcons/blue_libra.svg';
import bluescorpio from 'assets/icons/booksIcons/blue_scorpio.svg';
import bluesagittarius from 'assets/icons/booksIcons/blue_sagittarius.svg';
import bluecapricorn from 'assets/icons/booksIcons/blue_capricorn.svg';
import blueaquarius from 'assets/icons/booksIcons/blue_aquarius.svg';
import bluepisces from 'assets/icons/booksIcons/blue_pisces.svg';

// Beige Icons
import beigearies from 'assets/icons/booksIcons/beige_aries.svg';
import beigetaurus from 'assets/icons/booksIcons/beige_taurus.svg';
import beigegemini from 'assets/icons/booksIcons/beige_gemini.svg';
import beigecancer from 'assets/icons/booksIcons/beige_cancer.svg';
import beigeleo from 'assets/icons/booksIcons/beige_leo.svg';
import beigevirgo from 'assets/icons/booksIcons/beige_virgo.svg';
import beigelibra from 'assets/icons/booksIcons/beige_libra.svg';
import beigescorpio from 'assets/icons/booksIcons/beige_scorpio.svg';
import beigesagittarius from 'assets/icons/booksIcons/beige_sagittarius.svg';
import beigecapricorn from 'assets/icons/booksIcons/beige_capricorn.svg';
import beigeaquarius from 'assets/icons/booksIcons/beige_aquarius.svg';
import beigepisces from 'assets/icons/booksIcons/beige_pisces.svg';

export default {
  arrowHeart,
  baby,
  balance,
  bigCheekSmile,
  books,
  brain,
  briefcase,
  brokenHeart,
  celebrate,
  cheekSmile,
  clock,
  colors,
  compass,
  correct,
  couple,
  crossedHands,
  diamond,
  disappointed,
  enjoy,
  ew,
  facePalm,
  family,
  finding,
  fireHeart,
  gift,
  graduation,
  gymnastic,
  headMassage,
  healingHeart,
  handHeart,
  hand,
  heart,
  idk,
  kissing,
  laugh,
  lily,
  little,
  loveLetter,
  loved,
  magicBall,
  meh,
  message,
  money,
  numbers,
  palmHand,
  palm,
  pinkHearts,
  planet,
  questionMark,
  ring,
  rocket,
  serious,
  smile,
  spade,
  spinningHearts,
  sponge,
  starEyes,
  target,
  thinking,
  tornado,
  wrong,
  yoga,
  backgroundZodiac,
  aries,
  taurus,
  gemini,
  cancer,
  leo,
  virgo,
  libra,
  sad,
  scorpio,
  sagittarius,
  smirk,
  capricorn,
  aquarius,
  pisces,
  reviewStars,
  blackaries,
  blacktaurus,
  blackgemini,
  blackcancer,
  blackleo,
  blackvirgo,
  blacklibra,
  blackscorpio,
  blacksagittarius,
  blackcapricorn,
  blackaquarius,
  blackpisces,
  purplearies,
  purpletaurus,
  purplegemini,
  purplecancer,
  purpleleo,
  purplevirgo,
  purplelibra,
  purplescorpio,
  purplesagittarius,
  purplecapricorn,
  purpleaquarius,
  purplepisces,
  redaries,
  redtaurus,
  redgemini,
  redcancer,
  redleo,
  redvirgo,
  redlibra,
  redscorpio,
  redsagittarius,
  redcapricorn,
  redaquarius,
  redpisces,
  greenaries,
  greentaurus,
  greengemini,
  greencancer,
  greenleo,
  greenvirgo,
  greenlibra,
  greenscorpio,
  greensagittarius,
  greencapricorn,
  greenaquarius,
  greenpisces,
  bluearies,
  bluetaurus,
  bluegemini,
  bluecancer,
  blueleo,
  bluevirgo,
  bluelibra,
  bluescorpio,
  bluesagittarius,
  bluecapricorn,
  blueaquarius,
  bluepisces,
  beigearies,
  beigetaurus,
  beigegemini,
  beigecancer,
  beigeleo,
  beigevirgo,
  beigelibra,
  beigescorpio,
  beigesagittarius,
  beigecapricorn,
  beigeaquarius,
  beigepisces,
};
